import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {history} from '../index';
import {Link} from 'react-router';

import config from '../config';
const copy = config.content.welcome;

interface WelcomeProps {
}

interface WelcomeState {
    show:boolean;
}

class Welcome extends React.Component<WelcomeProps, WelcomeState> {
    private videoRef: HTMLVideoElement = null;

    constructor(p) {
        super(p);

        this.state = {
            show:false
        }
    }

    getStarted(changeRoute: boolean = true) {
        if (!this.videoRef.paused) {
            this.videoRef.pause();
        }

        if (changeRoute) {
            history.push('/create/intro');
        }
    }

    componentDidMount(){

        setTimeout(()=>{
            this.setState({
                show:true
            })
        }, 100)
    }

    onVideoPlay = (): void => {
        if (this.videoRef.currentTime == 0) {
            (window as any).ga('send', 'event', 'UGC Commercial', 'Video Play', 'Hero');
        }
    }

    render() {

        const show = (this.state.show) ? 'show' : '';

        return (
            <section className={`welcome ${show}`}>
                <div className = "hero">
                    <div className = "bg"></div>
                    <div className = "content">
                        <div className = "content-wrapper">
                            <div className = "copy">
                                <span className = "bd create">{copy.hero_one}</span>
                                <div className = "rules"><span className = "it">{copy.hero_two}</span></div>
                                <span className = "bd commercial">{copy.hero_three}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "info">
                    <div className = "col">
                        <h1>{copy.headline}</h1>
                        <p>{copy.description}</p>

                        <div className="get-started-wrapper" onClick = {()=>this.getStarted()}>
                            <span>{copy.btn_get_started}</span>
                            <div className="arrow" />
                        </div>
                    </div>
                    <div className = "col">
                        <video
                            controls
                            id = "preview"
                            src= "https://imposium-dev-renders.s3.amazonaws.com/F9J336Cag1U9idlaX-d7k5scn-A4Zdn4V7U4E-V6u9saaep1ma50q-1118D5Sb/88K2W7qbf-K3l6FaVb00ncofG-D0r4G1W1Tb.mp4"
                            poster = "/img/welcome_cover.jpg"
                            ref = {(e) => this.videoRef = e}
                            preload = 'preload'
                            onPlay = {() => this.onVideoPlay()} />
                    </div>
                </div>
                <Link to = "/create/intro">
                    <div id = "btn-get-started-mobile" onClick = {() => this.getStarted(false)} className = "btn btn-default">
                        {copy.btn_get_started}<i className = "icon-next" />
                    </div>
                </Link>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);