
import * as Bowser from 'bowser';
import config from '../../config';
import actions from '../actions/settings';

const uaParser: any = Bowser.getParser(window.navigator.userAgent);
const validBrowser: boolean = uaParser.satisfies(config.browser);
const {name} = uaParser.getBrowser();

const initialState = {
    mobileMode: false,
    isChrome: (name.toLowerCase() === 'chrome'),
    isDeeplink: false,
    validBrowser
};

function settings(state = initialState, action) {
    switch (action.type) {
        case actions.SET_MODE:
            let mobileMode: boolean = false;

            if (uaParser.getPlatformType() == 'mobile') {
                mobileMode = true;
            } else {
                if (Math.floor(window.innerWidth) < config.MOBILE_BREAK_POINT) {
                    mobileMode = true;
                }
            }

            return {
                ...state,
                mobileMode
            };
        case actions.SET_DEEPLINK_STATUS:
            return {
                ...state,
                isDeeplink: action.toggle
            };
        case actions.IGNORE_WARNING:
            return {
                ...state,
                validBrowser: true
            };
        default:
            return state;
    }
}

export default settings;
