export const setMode = (): any => {
    return (dispatch) => {
        dispatch({type: actions.SET_MODE});
    }
}

export const setDeeplinkStatus = (toggle: boolean): any => {
    return (dispatch) => {
        dispatch({type: actions.SET_DEEPLINK_STATUS, toggle});
    }
}

export const ignoreWarning = (): any => {
    return (dispatch) => {
        dispatch({type: actions.IGNORE_WARNING});
    }
}

const actions: any = {
    SET_MODE: 'settings/SET_MODE',
    SET_DEEPLINK_STATUS: 'settings/SET_DEEPLINK_STATUS',
    IGNORE_WARNING: 'settings/IGNORE_WARNING'
};

export default actions;
