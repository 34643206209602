
const config = {
    "MOBILE_BREAK_POINT":1024,
    "imposium":{
        "storyId":"cb29de7d-983e-4b1a-ab3c-71af1692998e",
        "accessToken":"oj6eer0Eef6jiengeivaeW4na1ooSh7e"
    },
    "browser": {
        "internet explorer": ">10",
        "firefox": ">61",
        "chrome": ">68",
        "safari": ">10",
        "Microsoft Edge": ">16",
        "mobile": {
            "safari": ">9",
            "Samsung Internet for Android": ">3"
        }
    },
    "content":{
        "warning": {
            "title": "one thing!",
            "body": "We noticed you’re using an older browser. This video tool works best with the following:",
            "button": "proceed anyway",
            "chrome_cta": "Google Chrome",
            "chrome_link": "https://www.google.com/chrome/",
            "firefox_cta": "Mozilla Firefox",
            "firefox_link": "https://www.mozilla.org/en-US/firefox/new/"
        },
        "header":{
            "btn_home":"Home",
            "links":[
                {
                    "label":"Welcome",
                    "section":"welcome",
                },
                {
                    "label":"Step 1",
                    "section":"intro",
                },
                {
                    "label":"Step 2",
                    "section":"trends",
                },
                {
                    "label":"Step 3",
                    "section":"outro"
                }
            ]
        },
        "welcome":{
            "section":"welcome",
            "hero_one":"Create",
            "hero_two":"your own",
            "hero_three":"Meetup Invitation",
            "headline":"Let's Meet Up at CES",
            "description":"Personalized video from Imposium is powerful. It uses data to deliver more relevant messages that increase conversion rates up to 250%. Cut through the noise at CES by creating your personalized video to tell people what you're here to see and help arrange your next meeting.",
            "btn_get_started":"Let’s get Started"
        },
        "intro":{
            "number":1,
            "section":"intro",
            "step":"Step 1",
            "header":"Invitation Introduction",
            "description":"Let people know you're here!",
            "intro_copy":"Intro Text",
            "trend_copy":"Trend Text",
            "btn_previous":"Welcome",
            "btn_next":"Step 2",
            "errors": {
                "customers": "Please enter your customer information"
            }
        },
        "trends":{
            "number":2,
            "section":"trends",
            "step":"Step 3",
            "header":"Your Top Trends",
            "description":"Now select what you're excited to talk about.",
            "btn_clear":"Clear Selected",
            "btn_previous":"Step 2",
            "btn_next":"Step 4",
            "error": "Please make sure to select three clips",
            "clips":[
                {
                    "id":4,
                    "tag":"blockchain",
                    "label":"Blockchain"
                },
                {
                    "id":5,
                    "tag":"ai",
                    "label":"Artificial Intelligence"
                },
                {
                    "id":6,
                    "tag":"automotive",
                    "label":"Self-Driving Cars"
                },
                {
                    "id":7,
                    "tag":"wearables",
                    "label":"Wearables"
                },
                {
                    "id":14,
                    "tag":"smarthome",
                    "label":"IoT"
                },
                {
                    "id":9,
                    "tag":"ar-vr",
                    "label":"AR / VR"
                },
                {
                    "id":26,
                    "tag":"voice",
                    "label":"Voice Activated Technology"
                },
                {
                    "id":17,
                    "tag":"biometrics",
                    "label":"Sensors and Biometrics"
                },
                {
                    "id":18,
                    "tag":"smartcity",
                    "label":"Smart Cities"
                },
                {
                    "id":23,
                    "tag":"smarthome",
                    "label":"Smart Homes"
                },
                {
                    "id":20,
                    "tag":"sustainability",
                    "label":"Sustainability"
                },
                {
                    "id":25,
                    "tag":"esports",
                    "label":"eSports"
                },
                {
                    "id":2,
                    "tag":"blockchain",
                    "label":"Cyber Security and Privacy"
                },
                {
                    "id":12,
                    "tag":"video",
                    "label":"Content and Streaming Services"
                },
                {
                    "id":27,
                    "tag":"health",
                    "label":"Digital Health"
                },
                {
                    "id":3,
                    "tag":"hardware",
                    "label":"Digital Finance / E-Commerce"
                },
            ]
        },
         "outro":{
            "number":3,
            "section":"contact",
            "step":"Step 3",
            "btn_previous":"Step 2",
            "btn_create":"Create Video",
            "header":"Meetup Location and Signoff",
            "description":"Tell them where they can find you and when! Make as many as you like to really put 1:1 to the test.",
            "event_copy":"Event Text",
            "outro_copy":"End Card Text"
        },
        "video":{
            "sub_header":"Let's meetup and we can discuss our thoughts on these top 2019 CES trends.",
            "header":"Check out my personalized CES Video!",
            "header_default": "You're Done!",
            "sub_header_default": "Make sure to download a copy of your new video for future sharing.",
            "btn_download":"Download",
            "btn_copy":"Copy Link",
            "share":"Share",
            "btn_make_another":"Make Another Video",
            "tooltip": "Note: Some links may expire in 2020, so be sure to download a copy for future sharing!"
        },
        "sharing": {
            "twitter": "Let's meet up at CES! See my predictions for top 2019 trends.",
            "email_subject": "Let's Meetup at CES!",
            "email_body": "I'm going to CES, are you? See my predictions for top trends and find me at [event info] to dicuss while you're there. [url]",
        },
        "about": {
            "header": "About Imposium",
            "p1":"Imposium is a premier video rendering platform that creates personalized advertising at scale using real-time data. Recently selected for the R/GA + Verizon Ventures accelerator program for media tech ventures in New York.",
            "p2":"We put audiences into brand stories, creating highly relevant, entertaining advertising that stays with users well after they've watched.",
            "p3":"Using targeted data, we automate the personalization process to deliver massive amount of videos that feel like they are made for each individual consumer.",
            "p4":"At the core of our business is premium creative. Our work took home an Emmy for the viral campaign Take This Lollipop, that put Facebook users into a chilling story, earning +600MM views globally.",
            "p5":"For more information how Imposium can help your business, please contact",
            "email":"jenna@imposium.com"
        },
        "modal": {
            "title": "processing",
            "body": "We’re rendering your video - it should only take a few seconds. Once it's complete, you'll be taken to a page to view and share your video.",
            "btn_back": "back"
        }
    }
}

export default config;
