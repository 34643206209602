import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateInventory} from '../redux/actions/inventory';
import {validateSection} from '../redux/actions/validation';
import {Link} from 'react-router';

import Preview from './Preview';
import ModuleHeader from './ModuleHeader';
import ModuleButtons from './ModuleButtons';

import config from '../config';
const copy = config.content.about;

interface AboutProps {
    settings:any;
    color:string;
}

interface AboutState {}

class About extends React.Component<AboutProps, AboutState> {
    private inputRefs: any = {};

    constructor(p) {
        super(p);
    }


    render() {

        const {settings: {mobileMode}} = this.props;
        const cls = (mobileMode) ? 'mobile' : 'desktop';

        return (
            <section className = {`about ${this.props.color} ${cls}`}>
                <div className = {`about-header ${cls}`}>
                    <div className = "number"></div>
                    <h2>{copy.header}</h2>
                    <div className="rule"></div>
                </div>
                <p className = "desc">{copy.p1}</p>
                <p className = "desc">{copy.p2}</p>
                <p className = "desc">{copy.p3}</p>
                <p className = "desc">{copy.p4}</p>
                <p className = "desc">{copy.p5}&nbsp;<a href = {`mailto:${copy.email}`}>{copy.email}</a></p>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateInventory, validateSection}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(About);