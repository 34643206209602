import * as React from 'react';

export class Facebook extends React.PureComponent<{}, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="26.35" height="56.1" viewBox="0 0 26.35 56.1">
                <title>facebook</title>

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M6,56.1V28.4H0V18.65H6V11C6,3.76,9.84,0,18.39,0h8V10H20.13c-2.2,0-2.65.9-2.65,3.17v5.49h8.87l-.85,9.63h-8V56.1Z" fill="#fff"/>
                    </g>
                </g>
            </svg>
        );
    }
}

export class Twitter extends React.PureComponent<{}, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="55.96" height="45.44" viewBox="0 0 55.96 45.44">
                <title>twitter</title>

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M17.6,45.44A32.56,32.56,0,0,1,0,40.29a23.11,23.11,0,0,0,17-4.76,11.48,11.48,0,0,1-10.73-8,11.58,11.58,0,0,0,5.19-.2A11.48,11.48,0,0,1,2.25,16a11.32,11.32,0,0,0,5.2,1.43A11.47,11.47,0,0,1,3.9,2.1a32.6,32.6,0,0,0,23.66,12A11.49,11.49,0,0,1,47.12,3.62,22.87,22.87,0,0,0,54.41.84a11.41,11.41,0,0,1-5,6.34A22.81,22.81,0,0,0,56,5.38a23.28,23.28,0,0,1-5.73,5.94C51,27.45,38.92,45.44,17.6,45.44Z" fill="#fff"/>
                    </g>
                </g>
            </svg>
        );
    }
}

export class Email extends React.PureComponent<{}, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="60.44" height="44.31" viewBox="0 0 60.44 44.31">
                <title>email</title>

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M60.44,1.54a.69.69,0,0,0-.08-.39c-.07-.15-.07-.23-.15-.31a.26.26,0,0,1-.08-.23.07.07,0,0,1-.07-.07,1,1,0,0,0-.31-.23c-.08-.08-.15-.16-.23-.16s-.16-.07-.31-.07S59,0,58.83,0H1.77c-.16,0-.23.08-.39.08s-.23.07-.3.07S.92.31.84.31.61.46.54.54.46.54.46.61.38.77.31.84a.62.62,0,0,0-.16.31.77.77,0,0,0-.07.39c0,.07-.08.15-.08.23V42.55a1.73,1.73,0,0,0,1.77,1.76h56.9a1.73,1.73,0,0,0,1.77-1.76v-41Zm-6.6,2L30.26,24.12,6.68,3.53ZM3.53,40.78V5.53l25.58,22.2a1.52,1.52,0,0,0,1.15.38,2.05,2.05,0,0,0,1.15-.38l25.5-22.2V40.78Z" fill="#fff"/>
                    </g>
                </g>
            </svg>
        );
    }
}

export class Linkedin extends React.PureComponent<{}, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="50.44" height="50.42" viewBox="0 0 50.44 50.42">
                <title>linkedin</title>

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                            <path d="M6.16,12.4a6.2,6.2,0,1,1,6.16-6.2A6.18,6.18,0,0,1,6.16,12.4Z" fill="#fff"/>
                            <rect x="1" y="16.81" width="10.41" height="33.61" fill="#fff"/>
                            <path d="M18.07,50.42V16.81h10v4.52A11.73,11.73,0,0,1,38.2,15.78c7.13,0,12.24,4.35,12.24,13.36V50.42H40.08V32.78c0-4.84-1.84-7.54-5.67-7.54-4.17,0-6.35,2.81-6.35,7.54V50.42Z" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}