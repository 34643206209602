export const toggleCropper = (toggle: boolean) => {
    return (dispatch) => {
        dispatch({type: actions.TOGGLE, toggle});
    }
}

export const setFile = (file: Blob) => {
    return (dispatch) => {
        dispatch({type: actions.SET_FILE, file});
    }
}

const actions: any = {
    TOGGLE: 'cropper/TOGGLE',
    SET_FILE: 'cropper/SET_FILE'
};

export default actions;