
import actions from '../actions/modal';

const initialState: any = {
    open: false
};

function modal(state = initialState, action) {
    switch (action.type) {
        case actions.TOGGLE:
            return {
                ...state,
                open: action.toggle
            };
        default:
            return state
    }
}

export default modal;