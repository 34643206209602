import * as React from 'react';
import config from '../config';

const ModuleHeader = ({copy, mobileMode}) => {

    const cls = (mobileMode) ? 'mobile' : 'desktop';
    return (
        <div className = {`module-header ${cls}`}>
            <div className = "number">{copy.number}</div>
            <h2>{copy.header}</h2>
            <div className="rule"></div>
        </div>
    );
}

export default ModuleHeader;