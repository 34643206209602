import axios, {AxiosResponse, AxiosError} from 'axios';

export default class Sharing {
    private static readonly defaultWindowWidth: number = 550;
    private static readonly defaultWindowHeight: number = 480;
    private static readonly twitterBaseUrl: string = 'https://twitter.com/intent/tweet';
    private static readonly trailingFeatures: string = ',status,resizable,scrollbars,modal,alwaysRaised';
    private static readonly linkedinBaseUrl: string = 'http://www.linkedin.com/shareArticle?mini=true';

    public static doFacebookShare = (href: string): void => {
        if ((window as any).FB_READY) {
            const props: any = {href, method: 'share'};

            (window as any).FB['ui'](props, (res: any) => {
                if (res && !res.error_code) {
                    (window as any).ga('send', 'event', 'UGC Commercial', 'Share Complete', 'FB');
                }
            });
        }
    }

    public static doTwitterShare = (href: string, copy: string): void => {
        const shareUrl: string = `${Sharing.twitterBaseUrl}?url=${encodeURIComponent(href)}&text=${copy}`;
        Sharing.openWindow(shareUrl, Sharing.defaultWindowWidth, Sharing.defaultWindowHeight);
    }

    public static doLinkedinShare = (href: string): void => {
        const shareUrl: string = `${Sharing.linkedinBaseUrl}&url=${encodeURIComponent(href)}`;
        Sharing.openWindow(shareUrl, 550, 480);
    }

    public static doEmailShare = (subject: string, body: string, href: string): void => {
        const subjectEncoded: string = encodeURIComponent(subject);
        const bodyEncoded: string = encodeURIComponent(body.replace('[url]', href));

        window.location.href = `mailto:?subject=${subjectEncoded}&body=${bodyEncoded}`;
    }

    public static hrefDownload = (href: string, filename: string): void => {
        const a: HTMLAnchorElement = document.createElement('a');

        document.body.appendChild(a);
        a.href = href;
        a.download = filename;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    public static ajaxDownload = (url: string, filename: string): void => {
        axios({
            url,
            method: 'GET',
            responseType: 'blob'
        })
        .then((res: AxiosResponse) => {
            const {data} = res;
            const reader: FileReader = new FileReader();

            reader.readAsDataURL(data);

            reader.onloadend = () => {
                const URL: any = window.URL || (window as any).webkitURL;
                
                const r: string = reader.result + '';
                const decodedChars: string = atob(r.slice(r.indexOf(',') + 1));
                const charCodes: Array<number> = new Array(decodedChars.length);
                
                let byteArr: Uint8Array = null;
                let blobUrl: string = null;

                for (let i = 0; i < decodedChars.length; i++) {
                    charCodes[i] = decodedChars.charCodeAt(i);
                }

                byteArr = new Uint8Array(charCodes);
                blobUrl = URL.createObjectURL(new Blob([byteArr], {type: "video\/mp4"}));
                Sharing.hrefDownload(blobUrl, filename);
            }
        })
        .catch((e: AxiosError) => {
            console.error('Failed to download video: ', e);
        });
    }

    private static openWindow = (url: string, width: number, height: number): void => {
        const left: number = (screen.width / 2) - (width / 2);
        const top: number = (screen.height / 2) - (height / 2 + 100);
        const cacheBuster: string = new Date().getTime().toString();
        const features = `width=${width},height=${height}${Sharing.trailingFeatures},left=${left},top=${top},screenX=${left},screenY=${top}`;

        window.open(url, cacheBuster, features);
    }
}