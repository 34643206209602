import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import settings from './settings';
import inventory from './inventory';
import modal from './modal';
import validation from './validation';
import cropper from './cropper';
import prevExperience from './prev-experience';

const rootReducer = combineReducers({
    settings,
    inventory,
    modal,
    validation,
    cropper,
    prevExperience,
    routing: routerReducer
});

export default rootReducer;