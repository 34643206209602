import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {history} from '../index';
import {Link} from 'react-router';

interface ModuleProps {
    activeModule:string;
    section:string;
    mobileMode:boolean;
    color?:string;
}

interface ModuleState {
    active:boolean;
    scrollTriggered:boolean;
}

class Module extends React.Component<ModuleProps, ModuleState> {
    
    private scrollHandler:any;
    private moduleContainer:any;
    private topOffset:number = 200;
    private targetPadding:number = 200;

    constructor(p) {
        super(p);

        this.scrollHandler = (e)=>this.onScroll(e);
        this.moduleContainer = React.createRef();

        this.state = {
            active:false,
            scrollTriggered:false
        };
    }

    getOffset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    onScroll(e){

        const cont:any = this.moduleContainer.current;

        if(cont && !this.props.mobileMode){

            const targTop = this.getOffset(cont).top - this.getHeaderOffset() - this.topOffset;
            const height = cont.clientHeight;
            const targBottom = targTop + height - this.topOffset;
            const pageY = window.pageYOffset;

            if(pageY > targTop && pageY < targBottom && !this.state.active){
                this.setState({
                    scrollTriggered:true
                }, ()=>{
                    history.push('/create/' + this.props.section);
                })
            }
        }
    }

    scrollToModule(){    
        const cont:any = this.moduleContainer.current;    
        if(cont){    
            const top = this.getOffset(cont).top - this.getHeaderOffset();    
            window.scrollTo(0, top);    
        }    
    }

    getHeaderOffset(){

        if(window.innerWidth < 1440){
            return 97;
        }else{
            return 125;
        }
    }

    checkActive(){

        if(this.props.activeModule == this.props.section && !this.state.active){

            if(!this.state.scrollTriggered && !this.props.mobileMode){    
                this.scrollToModule();    
            }

            this.setState({
                active:true,
                scrollTriggered:false
            }, ()=>{
                if(this.props.mobileMode){
                    window.scrollTo(0, 0); //Force scroll to the top of the page on mobile
                }
            });

        }else if(this.props.activeModule != this.props.section && this.state.active){
            this.setState({
                active:false,
                scrollTriggered:false
            });
        }
    }

    componentDidUpdate(prevProps){

        this.checkActive();
    }

    componentDidMount(){

        this.checkActive();
        document.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount(){

        document.removeEventListener('scroll', this.scrollHandler);
    }

    render() {
        const mobileClass = (this.props.mobileMode) ? '' : 'desktop-mode';
        const active = (this.state.active) ? 'active' : '';
        const color = (this.props.color) ? this.props.color : '';

        return (
            <div ref = {this.moduleContainer} className={`module ${active} ${color} ${mobileClass}`}>
                {this.props.children}
            </div>
        );
    }
}

export default Module;