    import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateInventory} from '../redux/actions/inventory';

interface ClipProps {
    tag:string;
    id: number;
    label: string;
    disabled: boolean;
    selectionNumber: number;
    inventory: any;
    onClick: (e: any, id: number) => any;
    updateInventory: (d: any) => any;
    mobileMode: boolean;
}

interface ClipState {
    videoSrc: string;
    posterSrc: string;
    bgImage: string;
    canPlay: boolean;
    playing:boolean;
}

class Clip extends React.Component<ClipProps, ClipState> {
    private static readonly yearsExpId: number = 1918;

    private videoRef: HTMLVideoElement = null;
    private expRef: HTMLInputElement = null;
    private canPlay: boolean = false;

    private personSelect:any;
    private nameInput:any;

    constructor(p) {
        super(p);

        this.personSelect = React.createRef();
        this.nameInput = React.createRef();

        this.state = {
            videoSrc: '',
            posterSrc: '',
            bgImage: '',
            canPlay: false,
            playing: false
        };
    }

    componentWillMount() {
        const {tag} = this.props;

        this.setState({
            videoSrc: `/vid/${tag}.mp4`,
            posterSrc: `/img/posters/${tag}.jpg`,
            bgImage: `url('/img/posters/${tag}.jpg') center center / cover no-repeat`
        });
    }

    componentDidUpdate(prevProps: ClipProps) {
        const {id, selectionNumber} = this.props;

        if (id === Clip.yearsExpId && selectionNumber && !prevProps.selectionNumber) {
            this.expRef.focus();
        }
    }

    onCanPlay = (): void => {
        this.setState({canPlay: true}, ()=>{
            if(this.state.playing){
                this.videoRef.play();
            }
        });
    }

    onMouseEnter = (): void => {
        const {label} = this.props;
        
        this.setState({
            playing:true
        }, ()=>{
            if(this.state.canPlay){
                (window as any).ga('send', 'event', 'UGC Commercial', 'Play Animation', label);
                this.videoRef.play();
            }else{
                this.videoRef.load();
            }   
        });
    }

    onMouseLeave = (): void => {
        this.setState({
            playing:false
        }, ()=>{
            if(this.state.canPlay){
                this.videoRef.pause();
            }
        });
    }

    onExperienceInput = (e: any): void => {
        const {updateInventory} = this.props;
        const {target: {value: years_experience}} = e;

        if (years_experience <= 100) {
            updateInventory({years_experience});
        }
    }

    getExperienceInput = (): JSX.Element => {
        const {inventory: {years_experience}} = this.props;

        return (
            <div className = "experience-wrapper">
                <input
                    min = "1"
                    max = "100"
                    type = "number"
                    value = {years_experience}
                    ref = {(e) => this.expRef = e}
                    onChange = {(e) => this.onExperienceInput(e)} />

                <div className = "years">
                    years
                </div>
            </div>
        );
    }

    personChanged(){

        const {selectionNumber} = this.props;

        const select = this.personSelect.current;
        if(select){
            let inv = {};
            inv[`cookie_${selectionNumber}_person`] = select.value; 
            this.props.updateInventory(inv);
        }
    }
    render() {
        const {videoSrc, posterSrc, bgImage} = this.state;
        const {id, disabled, onClick, selectionNumber, label} = this.props;
        
        const selected = (selectionNumber) ? 'selected' : '';
        const disabledClass: string = (disabled) ? 'disabled' : '';

        const onClickProxy: any = (!disabled) ? (e: any) => onClick(e, id) : null;
        const onEnter: any = (!disabled) ? () => this.onMouseEnter() : null;
        const onLeave: any = (!disabled) ? () => this.onMouseLeave() : null;

        const cls = (this.state.canPlay) ? 'show' : '';

        const selectionIndicator: JSX.Element = (!disabled) 
            ? (<div className = {`selection-indicator ${selected}`}>{this.props.selectionNumber}</div>)
            : null;

        const loop =! this.props.mobileMode;

        return (
            <div
                key = {id}
                className = {`clip ${disabledClass}`}
                onClick = {onClickProxy}>

                <img src = {posterSrc}/>
                
                {selectionIndicator}
                
                <div className = "overlay">
                    <div className = "label">{label}</div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {inventory: state.inventory, mobileMode:state.settings.mobileMode};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateInventory}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Clip);