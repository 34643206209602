import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toggleModal} from '../redux/actions/modal';
import {setPrevExperience} from '../redux/actions/prev-experience';

import {Events} from 'imposium-js-sdk';
import {imposiumClient, history} from '../index'; 

import config from '../config';
const copy = config.content.modal;

interface ModalProps {
    modal: any;
    inventory: any;
    toggleModal: (toggle: boolean) => any;
    setPrevExperience: (prevExperience: any) => any;
}

class Modal extends React.PureComponent<ModalProps, null> {
    constructor(p) {
        super(p);
    }

    componentDidMount() {
        const {inventory} = this.props;

        imposiumClient.on(Events.GOT_EXPERIENCE, (e: any) => this.gotExperience(e));
        imposiumClient.createExperience(inventory);
    }

    doClose = (): void => {
        const {toggleModal} = this.props;

        imposiumClient.off(Events.GOT_EXPERIENCE);
        toggleModal(false);
    }

    gotExperience = (experience: any): void => {
        const {inventory: {name}, setPrevExperience} = this.props;
        const {id} = experience;

        this.doClose();
        setPrevExperience({name, experienceId: id});
        history.push(`/video/${id}`);
    }

    render() {
        return (
            <div className="modal-wrapper">
                <div className="bg" />

                <div className="content">
                    <h2 className="title">{copy.title}</h2>
                    <div className="rule pulse" />
                    <p>{copy.body}</p>
                    <div className="btn btn-default btn-back" onClick={() => this.doClose()}>
                        <i className="icon-back" />{copy.btn_back}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {modal: state.modal, inventory: state.inventory};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({toggleModal, setPrevExperience}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);