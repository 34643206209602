import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateInventory} from '../redux/actions/inventory';
import {showValidation, validateSection} from '../redux/actions/validation';
import {toggleModal} from '../redux/actions/modal';
import {Link} from 'react-router';

import Preview from './Preview';
import ModuleHeader from './ModuleHeader';
import ModuleButtons from './ModuleButtons';
import About from './About';

import config from '../config';
const copy = config.content.outro;

interface OutroInfoProps {
    settings: any;
    inventory: any;
    validation: any;
    activeModule:string;
    toggleModal: (toggle: boolean) => any;
    showValidation: (toggle: boolean) => any;
    updateInventory: (d: any) => any;
    validateSection: (sectionName: string, status: boolean) => any;
}

interface OutroInfoState {
    showMore: boolean;
}

class OutroInfo extends React.Component<OutroInfoProps, OutroInfoState> {
    private inputRefs: any = {};

    constructor(p) {
        super(p);

        this.state = {
            showMore: false
        };
    }

    updateForm = (e: any, label: string): void => {
        const {updateInventory} = this.props;
        const data = {[label]: e.target.value};
        updateInventory(data);
    }

    preventTab = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const {which} = e;
        if (which == 9) e.preventDefault();
    }

    getInput = (label: string, required: boolean = true): JSX.Element => {
        const {showMore} = this.state;
        const {inventory, inventory: {brand}, validation: {show, sections: {contact}}} = this.props;

        let type: string = 'text';
        let pattern: string = null;
        let showErrorClass: string = '';
        let input: JSX.Element = null;
        let errMarkup: JSX.Element = null;
        let preventTab: (e: React.KeyboardEvent<HTMLInputElement>) => void = () => {};

        input = (
            <input
                name = {label}
                type = {type}
                pattern = {pattern}
                required = {required}
                value = {inventory[label]} 
                placeholder = {copy[label]} 
                className = {`${label} ${showErrorClass}`}
                ref = {(e) => this.inputRefs[label] = e}
                onKeyDown = {(e) => preventTab(e)}
                onChange = {(e)=>this.updateForm(e, label)} />
        );

        return input;
    }

    getCreateButton = (): JSX.Element => {
        const {settings: {mobileMode}} = this.props;
        const rollOverClass: string = (!mobileMode) ? 'create-rollover' : '';
        const createIcon: JSX.Element = (mobileMode) ? <i className = "icon-next"/> : null;

        return (
            <div className = {`btn btn-default btn-next btn-create ${rollOverClass}`} onClick = {()=>this.doCreate()}>
                {copy.btn_create}{createIcon}
            </div>
        );
    }

    getMobileButtons = (): JSX.Element => {
        return (
            <div className = "module-buttons">
                <Link to = "/create/contact">
                    <div className = "btn btn-default btn-previous"><i className = "icon-previous"/>{copy.btn_previous}</div>
                </Link>
                {this.getCreateButton()}
            </div>
        );
    }

    doCreate = (): void => {
        const {
            settings: {mobileMode},
            validation: {show, sections, sections: {music}},
            showValidation,
            toggleModal
        } = this.props;

        toggleModal(true);
    }

    render() {

        const {showMore} = this.state;
        const {inventory, activeModule, settings: {mobileMode}, validation: {show, sections: {contact}}} = this.props;
        const mobileButtons: JSX.Element = (mobileMode) ? this.getMobileButtons() : null;
        const desktopButton: JSX.Element = (!mobileMode) ? this.getCreateButton() : null;
        let officeErrClass: string = '';

        return (
            <section className='info'>
                <ModuleHeader copy = {copy} mobileMode = {mobileMode} />
                <p className = "desc">{copy.description}</p>
                <div className="flex-wrapper">
                    <div className = "col">
                        <label className = "input-label">{copy.event_copy}</label>
                        {this.getInput('event_copy')}
                        <Preview active={activeModule} copy = {inventory.event_copy} bg = {'/img/event_preview.jpg'}/>
                    </div>
                    <div className = "col">
                        <label className = "input-label">{copy.outro_copy}</label>
                        {this.getInput('outro_copy')}
                        <Preview active={activeModule} copy = {inventory.outro_copy} bg = {'/img/outro_preview.jpg'}/>
                    </div>
                </div>
                {mobileButtons}
                {desktopButton}
                <About color = "black"/>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings, inventory: state.inventory, validation: state.validation};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateInventory, validateSection, toggleModal, showValidation}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OutroInfo);