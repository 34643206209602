import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateInventory} from '../redux/actions/inventory';
import {validateSection} from '../redux/actions/validation';
import {Link} from 'react-router';

import Preview from './Preview';
import ModuleHeader from './ModuleHeader';
import ModuleButtons from './ModuleButtons';

import config from '../config';
const copy = config.content.intro;

interface IntroInfoProps {
    settings: any;
    inventory: any;
    validation: any;
    activeModule:string;
    updateInventory: (d: any) => any;
    validateSection: (sectionName: string, status: boolean) => any;
}

interface IntroInfoState {
    showMore: boolean;
}

class IntroInfo extends React.Component<IntroInfoProps, IntroInfoState> {
    private inputRefs: any = {};

    constructor(p) {
        super(p);

        this.state = {
            showMore: false
        };
    }

    updateForm = (e: any, label: string): void => {
        const {updateInventory} = this.props;
        const data = {[label]: e.target.value};
        updateInventory(data);
    }

    preventTab = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const {which} = e;
        if (which == 9) e.preventDefault();
    }

    getInput = (label: string, locked: boolean = false): JSX.Element => {
        const {showMore} = this.state;
        const {inventory, inventory: {brand}, validation: {show, sections: {contact}}} = this.props;

        let type: string = 'text';
        let pattern: string = null;
        let showErrorClass: string = '';
        let input: JSX.Element = null;
        let errMarkup: JSX.Element = null;
        let preventTab: (e: React.KeyboardEvent<HTMLInputElement>) => void = () => {};

        input = (
            <input
                name = {label}
                type = {type}
                pattern = {pattern}
                disabled = {locked}
                value = {inventory[label]} 
                placeholder = ''
                className = {`${label} ${showErrorClass}`}
                ref = {(e) => this.inputRefs[label] = e}
                onKeyDown = {(e) => preventTab(e)}
                onChange = {(e)=>this.updateForm(e, label)} />
        );

        return input;
    }

    render() {

        const {showMore} = this.state;
        const {inventory, activeModule, settings: {mobileMode}, validation: {show, sections: {contact}}} = this.props;
        const mobileButtons: JSX.Element = (mobileMode) 
            ? (<ModuleButtons copy = {copy} sectionName = 'contact' prev = "/create/welcome/" next = "/create/trends"/>)
            : null;
        let officeErrClass: string = '';

        return (
            <section className='info'>
                <ModuleHeader copy = {copy} mobileMode = {mobileMode} />
                <p className = "desc">{copy.description}</p>
                <div className="flex-wrapper">
                    <div className = "col">
                        <label className = "input-label">{copy.intro_copy}</label>
                        {this.getInput('intro_copy')}
                        <Preview active={activeModule} copy = {inventory.intro_copy} bg = {'/img/intro_preview.jpg'}/>
                    </div>
                    <div className = "col">
                        <label className = "input-label">{copy.trend_copy}</label>
                        {this.getInput('trend_copy', true)}
                        <Preview active={activeModule} copy = {inventory.trend_copy} bg = {'/img/intro_preview.jpg'}/>
                        {mobileButtons}
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings, inventory: state.inventory, validation: state.validation};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateInventory, validateSection}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroInfo);