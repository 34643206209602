export const setPrevExperience = (prevExperience: any): any => {
    return (dispatch) => {
        dispatch({type: actions.SET, prevExperience});
    }
}

const actions: any = {
    SET: 'prev-experience/SET'
};

export default actions;