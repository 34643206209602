import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {showValidation} from '../redux/actions/validation';

import config from '../config';
const copy = config.content.header;
const links = config.content.header.links;

interface ActiveDistances {
    left: string;
    right: string;
}

interface HeaderProps {
    settings: any;
    validation: any;
    activeSection: string;
    showValidation: (toggle: boolean) => any;
}

interface HeaderState {
    drawerOpen: boolean;
    activePos: ActiveDistances;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    private static readonly linkClientWidth: number = 189;
    private static readonly defaultLinkWidth: number = 130;
    private static readonly validationSections: string[] = ['intro', 'trrends', 'outro'];

    private sectionNames: string[] = links.map((link: any) => {return link.section});
    private desktopSubNavRef: HTMLDivElement = null;

    private staggerTimeout: any = null;
    private staggerDelay: number = 100;

    constructor(p) {
        super(p);

        this.state = {
            drawerOpen: false,
            activePos: {left: '', right: ''}
        };
    }

    componentDidMount() {
        // const {activeSection} = this.props;
        // this.setActiveOffset(activeSection, activeSection);
        // window.addEventListener('resize', () => this.resize());
    }

    componentDidUpdate(prevProps: HeaderProps) {
        // const {activeSection} = this.props;
    //     if (activeSection !== prevProps.activeSection) {
    //         this.setActiveOffset(prevProps.activeSection, activeSection);
    //     }
    }

    resize = (): void => {
        // const {activeSection} = this.props;
        // clearTimeout(this.staggerTimeout);
        // this.setActiveOffset(activeSection, activeSection);
    }

    setActiveOffset = (prevSection: string, currSection: string): void => {
        if (currSection) {
            clearTimeout(this.staggerTimeout);
            
            const {left} = this.desktopSubNavRef.getBoundingClientRect();

            const prevIndex: number = this.sectionNames.indexOf(prevSection);
            const currIndex: number = this.sectionNames.indexOf(currSection);
            const diff: number = this.sectionNames.length - currIndex;

            const first: string = (currIndex > prevIndex) ? 'right' : 'left';
            const delayed: string = (currIndex > prevIndex) ? 'left' : 'right';

            const tmpDistances: ActiveDistances = {
                left: `${left + (currIndex * Header.linkClientWidth)}px`,
                right: `${((diff * Header.linkClientWidth) - Header.linkClientWidth) + left}px`
            };

            if (prevIndex != currIndex) {
                this.setState({
                    activePos: {...this.state.activePos, [first]: tmpDistances[first]}
                }, () => {
                    this.staggerTimeout = setTimeout(
                        () => {
                            this.setState({
                                activePos: {...this.state.activePos, [delayed]: tmpDistances[delayed]}
                            });
                        },
                        this.staggerDelay
                    );
                });
            } else {
                this.setState({activePos: tmpDistances});
            }
        }
    }

    getActiveText = (): string[] => {
        const {activeSection} = this.props;

        return links.map((link: any) => {
            const {section, label} = link;

            if (section == activeSection) {
                return label;
            }
        });
    }

    toggleDrawer = (section: string, validate: boolean): void => {
        const {validation: {show, sections}, showValidation, activeSection} = this.props;
        const activeSectionMod: string = (activeSection === 'photo') ? 'headshot' : activeSection;

        if (section) {
            if (validate && !show) {
              showValidation(true)
            }

            if (!validate && show) {
              showValidation(false);
            }  
        }

        this.setState({drawerOpen: !this.state.drawerOpen});
    }

    getLinks = (isMobile: boolean = true): JSX.Element[] => {
        const {validation: {sections}, activeSection} = this.props;

        return links.map((link: any, i: number) => {
            const {label, section} = link;

            let cls: string = (!isMobile && i == 0) ? 'first' : '';
            let defaultClass: string = 'header-link';
            let activeNext: string = `/create/${section}`;
            let toggle: any = null;
            
            if (isMobile) {
                const activeSectionMod: string = (activeSection === 'photo') ? 'headshot' : activeSection;
                const clickedSectionMod: string = (section === 'photo') ? 'headshot' : section;

                const activeIdx: number = Header.validationSections.indexOf(activeSectionMod);
                const clickedIdx: number = Header.validationSections.indexOf(clickedSectionMod);

                const activeValid: boolean = sections[activeSectionMod];
                const clickedValid: boolean = sections[clickedSectionMod];

                defaultClass = 'drawer-link';

                if ((activeValid || activeIdx == -1) && clickedIdx == activeIdx + 1) {
                    toggle = () => this.toggleDrawer(clickedSectionMod, false);
                } else {
                    if (clickedIdx !== -1 && !sections[Header.validationSections[clickedIdx]]) {
                        for (const key in sections) {
                            const sectionValid: boolean = sections[key];

                            if (!sectionValid) {
                                toggle = () => this.toggleDrawer(clickedSectionMod, true);
                                activeNext = `/create/${(key === 'headshot') ? 'photo' : key}`;
                                break;
                            }
                        }
                    } else {
                        if (clickedIdx !== -1 && clickedIdx > activeIdx) {
                            toggle = () => this.toggleDrawer(clickedSectionMod, true);
                            activeNext = '';
                        }
                    }
                }
            }

            return (
                <div
                    key = {i}
                    className = {`${defaultClass} ${cls}`}>
                    {label}
                </div>
            );
        });
    }

    render(){

        return (
            <header className="header">
                <div className = "header-content">
                    <a href = "https://imposium.com" target = "_blank"><div className = "logo-hustle"/></a>
                </div>  
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings, validation: state.validation};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({showValidation}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);