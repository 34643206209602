import 'blueimp-canvas-to-blob/js/canvas-to-blob.min.js';
require('array.prototype.find').shim();

import * as React from 'react';
import {render} from 'react-dom';
import {browserHistory, Router, Route, IndexRedirect, Redirect} from 'react-router';

import {Provider} from 'react-redux';
import {syncHistoryWithStore} from 'react-router-redux';
import store from './redux/store';

import App from './components/App';
import LandingPage from './components/LandingPage';
import VideoPage from './components/VideoPage';

import config from './config';
import * as Imposium from 'imposium-js-sdk';

const {imposium: {accessToken, storyId}} = config;
const windowDefined: boolean = (typeof window !== 'undefined');


export const imposiumClient: Imposium.Client = new Imposium.Client({accessToken, storyId});
export const history: any = (windowDefined) ? syncHistoryWithStore(browserHistory, store) : browserHistory;

render(
    <Provider store={store}>
        <Router history={history}>
            <Route path="/" component={App}>
                <IndexRedirect to="/create/welcome"/>
                <Route path = "create/:section" component = {LandingPage}/>
                <Route path = "video/:experience_id" component = {VideoPage}/>
            </Route>

            <Redirect from="*" to="/"/>
        </Router>
    </Provider>,
    document.getElementById('react-root')
);
