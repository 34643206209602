export const toggleModal = (toggle: boolean) => {
    return (dispatch) => {
        dispatch({type: actions.TOGGLE, toggle});
    }
}

const actions: any = {
    TOGGLE: 'modal/TOGGLE'
};

export default actions;