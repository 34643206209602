import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {validateSection} from '../redux/actions/validation';
import {updateInventory} from '../redux/actions/inventory';

import Clip from './Clip';
import ModuleHeader from './ModuleHeader';
import ModuleButtons from './ModuleButtons';
import ErrorBar from './ErrorBar';

import config from '../config';
const copy = config.content.trends;
const clips = config.content.trends.clips;

interface SelectClipsProps {
    settings: any;
    inventory: any;
    validation: any;
    updateInventory: (d: any) => any;
    validateSection: (sectionName: string, status: boolean) => any;
}

class SelectClips extends React.Component<SelectClipsProps, null> {
    private static readonly yearsExpId: number = 1918;

    constructor(p) {
        super(p);
    }

    componentDidUpdate(prevProps: SelectClipsProps) {
        const {
            inventory: {trend_1, trend_2, trend_3, years_experience},
            validateSection
        } = this.props;

        if (trend_1 && trend_2 && trend_3 && years_experience && !prevProps.inventory.years_experience) {
            validateSection('clips', true);
        }

        if (trend_1 && trend_2 && trend_3 && !years_experience && prevProps.inventory.years_experience) {
            validateSection('clips', false);
        }
    }

    clearSelections = (): void => {
        const {validateSection, updateInventory} = this.props;

        updateInventory({
            'trend_1': null,
            'trend_2': null,
            'trend_3' :null,
            'trend_1_tag': null,
            'trend_2_tag': null,
            'trend_3_tag' :null,
            'trend_1_copy':'',
            'trend_2_copy':'',
            'trend_3_copy':''
        });

        validateSection('clips', false);
    }

    clipClicked = (e: any, id: number, label: string, tag:string): void => {
        const {
            inventory,
            inventory: {trend_1, trend_2, trend_3, years_experience},
            validateSection,
            updateInventory
        } = this.props;
        
        const tools: number[] = [trend_1, trend_2, trend_3];

        if (!~tools.indexOf(id)) {
            for (let i = 1; i <= 3; i++) {
                const key = `trend_${i}`;
                const tagKey = `trend_${i}_tag`;
                const copyKey = `trend_${i}_copy`;

                if (inventory[key] == null) {
                    const data = {[key]: id, [tagKey]: tag, [copyKey]: label};
                    
                    (window as any).ga('send', 'event', 'UGC Commercial', 'Select Video', label);
                    updateInventory(data);

                    if (i == 3) {
                        if (!~tools.indexOf(SelectClips.yearsExpId) && id !== SelectClips.yearsExpId) {
                            validateSection('clips', true);
                        } else {
                            if (years_experience) {
                                validateSection('clips', true);
                            }
                        }
                    }

                    break;
                }
            }
        }
    }

    checkDisabledStatus = (id: number): boolean => {
        const {
            validation: {sections: {clips}},
            inventory: {trend_1, trend_2, trend_3}
        } = this.props

        const tools: number[] = [trend_1, trend_2, trend_3];

        return (trend_1 && trend_2 && trend_3 && !~tools.indexOf(id));
    }

    checkSelectionStatus = (id: number): number | null => {
        const {inventory} = this.props;
        let orderNo: number = null;

        for (let i = 1; i <= 3; i++) {
            if (id === inventory[`trend_${i}`]) {
                orderNo = i;
                break; 
            }
        }

        return orderNo;
    }

    pushClip = (arr: any[], clip: any): void => {
        const orderNo: number = this.checkSelectionStatus(clip.id)

        arr.push((
            <Clip 
                key = {clip.id}
                id = {clip.id}
                tag = {clip.tag}
                label = {clip.label}
                disabled = {this.checkDisabledStatus(clip.id)}
                selectionNumber = {orderNo}
                onClick = {(e, id)=>this.clipClicked(e, id, clip.label, clip.tag)}/>
        ));
    }

    getDesktopClips = (): JSX.Element => {
        const sections: JSX.Element[] = [];

        for (let i = 0; i < clips.length; i += 4) {
            const safeClips: any[] = [];

            if (clips[i]) {
                this.pushClip(safeClips, clips[i]);
            }

            if (clips[i + 1]) {
                this.pushClip(safeClips, clips[i + 1]);
            }

            if (clips[i + 2]) {
                this.pushClip(safeClips, clips[i + 2]);
            }

            if (clips[i + 3]) {
                this.pushClip(safeClips, clips[i + 3]);
            }

            sections.push((
                <div key={i} className = "clip-row">
                    {safeClips}
                </div>
            ));
        }

        return (
            <div className = "clips">
                {sections}
            </div>
        );
    }

    getMobileClips = (): JSX.Element => {
        return (
            <div className = "clips">
                {clips.map((clip, i)=>{
                    return <Clip 
                        key = {clip.id}
                        id = {clip.id}
                        tag = {clip.tag}
                        label = {clip.label}
                        disabled = {this.checkDisabledStatus(clip.id)}
                        selectionNumber = {this.checkSelectionStatus(clip.id)}
                        onClick = {(e, id)=>this.clipClicked(e, id, clip.label, clip.tag)}/>
                })}
            </div>
        );
    }

    render() {
        const {
            settings: {mobileMode},
            validation: {show, sections: {clips}},
            inventory: {trend_1, trend_2, trend_3, years_experience}
        } = this.props;

        const mobileClips: JSX.Element = (mobileMode) ? this.getMobileClips() : null;
        const mobileButtons: JSX.Element = (mobileMode)
            ? (<ModuleButtons copy = {copy} sectionName = "clips" prev = "/create/intro/" next = "/create/outro"/>)
            : null;
        
        const desktopClips: JSX.Element = (!mobileMode) ? this.getDesktopClips() : null; 

        const clearBtn: JSX.Element = (trend_1) 
            ? (<div className = "btn btn-default btn-clear-selection" onClick = {()=>this.clearSelections()}>{copy.btn_clear}</div>)
            : null;

        return (
            <section className = "select-clips">
                <ModuleHeader copy = {copy} mobileMode = {mobileMode} />

                <div className = "flex-wrapper">
                    <p className = "description">{copy.description}</p>
                    {mobileClips}
                    {desktopClips}
                    {clearBtn}
                    {mobileButtons}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings, inventory: state.inventory, validation: state.validation};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateInventory, validateSection}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectClips);