
export const updateInventory = (data: any): any => {
    return (dispatch) => {
        dispatch({type: actions.UPDATE, data});
    }
}

export const resetInventory = (): any => {
    return (dispatch) => {
        dispatch({type: actions.RESET});
    }
}

const actions: any = {
    UPDATE: 'inventory/UPDATE',
    RESET: 'inventory/RESET'
};

export default actions;
