import * as React from "react";
import {connect} from 'react-redux';

import Welcome from './Welcome';
import OutroInfo from './OutroInfo';
import IntroInfo from './IntroInfo';
import SelectClips from './SelectClips';
import Module from './Module';

import config from '../config';

interface LandingPageProps {
    settings: any;
    params: any;
}

class LandingPage extends React.PureComponent<LandingPageProps, null> {

    constructor(p) {
        super(p);
    }

    componentWillMount() {
        (window as any).ga('send', 'pageview', '/Commercial/Commercial');
    }

    render() {
        const {settings: {mobileMode}, params: {section}} = this.props;

        return (
            <div className="landing-page">
                <Module mobileMode = {mobileMode} activeModule={section} section = 'welcome'>
                    <Welcome />
                </Module>
                <Module mobileMode = {mobileMode} activeModule={section} section = 'intro' color = 'blue'>
                    <IntroInfo activeModule = {section} />
                </Module>
                <Module mobileMode = {mobileMode} activeModule = {section} section = 'trends' color = 'white'>
                    <SelectClips />
                </Module>
                <Module mobileMode = {mobileMode} activeModule = {section} section = 'outro' color = 'blue'>
                    <OutroInfo activeModule = {section} />
                </Module>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings};
}

export default connect(mapStateToProps, null)(LandingPage);