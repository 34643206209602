import actions from '../actions/cropper';

const initialState: any = {
    open: false,
    file: null
};

function cropper(state = initialState, action) {
    switch (action.type) {
        case actions.TOGGLE:
            return {
                ...state,
                open: action.toggle
            };
        case actions.SET_FILE:
            return {
                ...state,
                file: action.file
            };
        default:
            return state
    }
}

export default cropper;