import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showValidation} from '../redux/actions/validation';
import {Link} from 'react-router';

interface ModuleHeaderProps {
    copy: any;
    sectionName: string;
    prev: string;
    next: string;
    validation: any;
    showValidation: (toggle: boolean) => any;
}

class ModuleHeader extends React.PureComponent<ModuleHeaderProps, null> {
    constructor(p) {
        super(p);
    }

    onNext = (): void => {
        const {sectionName, validation: {show, sections}, showValidation} = this.props;

        if (!show && !sections[sectionName]) {
            showValidation(true);
        }

        if (show && sections[sectionName]) {
            showValidation(false);
        }
    }

    render() {
        const {copy, sectionName, prev, next, validation: {sections}} = this.props;
        // const activeNext: string = (sections[sectionName]) ? next : '';
        const clipsMargin: string = (sectionName == 'clips') ? 'clips-margin' : '';

        return (
            <div className = "module-buttons">
                <Link to = {prev}>
                    <div className = {`${clipsMargin} btn btn-default btn-previous`}>
                        <i className = "icon-previous"/><p>{copy.btn_previous}</p>
                    </div>
                </Link>

                <Link
                    to = {next}
                    onClick = {() => this.onNext()}>

                    <div className = "btn btn-default btn-next">
                        <p>{copy.btn_next}</p><i className = "icon-next"/>
                    </div>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {validation: state.validation};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({showValidation}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleHeader);