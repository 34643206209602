
import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setMode, setDeeplinkStatus} from '../redux/actions/settings';

import {history} from '../index';

import Header from './Header';
import Modal from './Modal';

import config from '../config';

interface AppProps {
    modal: any;
    settings: any;
    params: any;
    location: any;
    children: any;
    setMode: () => any;
    setDeeplinkStatus: (toggle: boolean) => any;
}

interface AppState {

}

class App extends React.Component<AppProps, AppState> {
    constructor(p) {
        super(p);
    }

    componentWillMount() {
        const {setMode, setDeeplinkStatus, params: {experience_id}} = this.props;

        if (experience_id) {
            const {originalExperience} = (window as any);
            
            if (experience_id === originalExperience) {
                setDeeplinkStatus(true);
            }
        }

        setMode();
        window.addEventListener('resize', () => setMode());
    }

    componentDidUpdate() {
        const {settings: {isDeeplink}, params: {experience_id}, setDeeplinkStatus} = this.props;
        const {originalExperience} = (window as any);

        if (isDeeplink && experience_id != originalExperience) {
            setDeeplinkStatus(false);
        }
    }

    render() {
        const {
            children,
            modal: {open: modalOpen},
            params: {section},
            settings: {validBrowser, mobileMode, isDeeplink}
        } = this.props;

        const processingModal: JSX.Element = (modalOpen) ? <Modal /> : null;
        const noPaddingClass: string = (isDeeplink) ? 'no-padding' : '';

        return (
            <div className = {`app ${noPaddingClass}`}>
                {processingModal}
                <Header activeSection = {section}/>
                {children}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {modal: state.modal, settings: state.settings, cropper: state.cropper};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setMode, setDeeplinkStatus}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);