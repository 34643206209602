
import inventoryActions from '../actions/inventory';

const initialState = {
    'trend_copy':'My 2019 Trends To Watch',
    'intro_copy':"Jenna from Imposium is going to IPG Women's Breakfast CES!",
    'event_copy':"I'll be at the IPG Fairway Villas at the Wynn",
    'outro_copy':'See You There at 8:30am on the 9th',
    'trend_1':null,
    'trend_2':null,
    'trend_3':null,
    'trend_1_tag':null,
    'trend_2_tag':null,
    'trend_3_tag':null,
    'trend_1_copy':'',
    'trend_2_copy':'',
    'trend_3_copy':''
};

function inventory(state = initialState, action) {
    switch (action.type) {
        case inventoryActions.UPDATE:
            return {...state, ...action.data};
        case inventoryActions.RESET:
            return {...initialState};
        default:
            return state
    }
}

export default inventory;
