import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

interface PreviewProps {
    footerText:string;
    active: string;
    inventory:any;
    bg:any;
    copy:any;
}

interface PreviewState {

}

class Preview extends React.Component<PreviewProps, PreviewState> {

    private canvasWidth:number = 720;
    private canvasHeight:number = 720;

    private infoX:number = 0;
    private infoY:number = 0;
    private infoScale:number = 1;
    private infoWidth:number = 720;
    private fieldPadding:number = 70;
    private container:any;
    private canvas:any;
    private bg:any;
    private info:any;
    private ctx:any;
    private resizeHandler:()=>void;

    //Elements for card overlay, need refs for dynamic font sizing
    private copy:any;

    constructor(p) {
        super(p);

        this.container = React.createRef();
        this.canvas = React.createRef();
        this.info = React.createRef();

        //Card overlay refs
        this.copy = React.createRef();

        this.resizeHandler = ()=>this.resize();
    }

    resize(){

        //scale the preview container relative to the screen size
        const node = this.container.current;
        const cvs = this.canvas.current;
        const info = this.info.current;

        if (node) {
            const w = node.clientWidth;
            const ratio = 1/1;
            const h = Math.round(w/ratio);
            node.style.height = `${h}px`;

            if(cvs){
                cvs.style.width = `${w}px`;
                cvs.style.height = `${h}px`;
            }

            const infoRatio = w / this.canvasWidth;
            const x = this.infoX * infoRatio;
            const y = this.infoY * infoRatio;
            const scale = infoRatio * this.infoScale;

            if(info){
                info.style.top = `${y}px`;
                info.style.left = `${x}px`;
                info.style.transform = `scale(${scale})`;
            }
        }
    }

    componentDidMount(){

        this.ctx = this.canvas.current.getContext('2d');
        this.ctx.globalCompositeOperation = 'multiply';

        this.loadBG();
        this.fitText(null);

        window.addEventListener('resize', this.resizeHandler, true);
        this.resize();
    }

    componentDidUpdate(prevProps: PreviewProps) {

        const {active} = this.props;

        if (active !== prevProps.active) {
            this.resize();
        }

        this.fitText(prevProps);
    }

    fitText(prevProps){

        //Copy
        const copy = this.copy.current;
        if(copy){

        }if(!prevProps){
            this.fitElement(copy, this.infoWidth-this.fieldPadding);
            return;
        }else if(this.props.copy !== prevProps.copy){
            this.fitElement(copy, this.infoWidth-this.fieldPadding);
        }
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.resizeHandler);
    }

    loadBG(){

        this.bg = new Image();
        this.bg.onload = ()=>this.drawCanvas();
        this.bg.src = this.props.bg;
    }

    drawCanvas(){

        this.clearCanvas();
        this.drawBG();
    }


    drawBG(){
        this.ctx.drawImage(this.bg, 0, 0, this.canvasWidth, this.canvasHeight, 0, 0, this.canvasWidth, this.canvasHeight);
    }

    clearCanvas(){
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }

    fitElement(element, targSize){

        console.log("FIT");

        const computedStyle = window.getComputedStyle(element, null);

        element.style.fontSize = '';
        element.style.letterSpacing = '';

        let fSize = 150;
        let lHeight = 130;
        const lRatio = fSize / lHeight;
        
        element.style.fontSize = `${fSize}px`;
        element.style.lineHeight = `${lHeight}px`;

        let w = element.offsetWidth;
        let h = element.offsetHeight;

        while(w > targSize || h > targSize){

            fSize--;
            lHeight = fSize / lRatio;

            element.style.fontSize = `${fSize}px`;
            element.style.lineHeight = `${lHeight}px`;

            w = element.offsetWidth;
            h = element.offsetHeight;
        }
    }

    render() {

        const copy = (this.props.copy);

        return (
            <div ref = {this.container} className = "preview">
                <canvas ref = {this.canvas } width = {`${this.canvasWidth}`} height = {`${this.canvasHeight}`}/>
                <div className = "overlay" ref = {this.info}>
                    <div className = "text-wrapper">
                        <div ref = {this.copy} className = "text">{copy}</div>  
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {inventory:state.inventory};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview);