import * as React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {resetInventory} from '../redux/actions/inventory';
import {resetValidation} from '../redux/actions/validation';

import * as copyToClipboard from 'copy-to-clipboard';

import {imposiumClient, history} from '../index'
import {Player, Events, PlayerEvents} from 'imposium-js-sdk';

import {Facebook, Twitter, Email, Linkedin} from './Icons';
import About from './About';
import Sharing from '../util/sharing';
import config from '../config';

const copy: any = config.content.video;
const shareCopy: any = config.content.sharing;

interface Platforms {
    facebook: string;
    twitter: string;
    linkedin: string;
    email: string;
}

interface ShareIcons {
    facebook: JSX.Element;
    twitter: JSX.Element;
    linkedin: JSX.Element;
    email: JSX.Element;
}

interface VideoPageProps {
    params: any;
    settings: any;
    inventory: any;
    prevExperience: any;
    resetInventory: () => any;
    resetValidation: () => any;
}

interface VideoPageState {
    experienceData: any;
    title: any;
    subTitle: any;
    showTooltip: boolean;
}

declare var jwplayer;


class VideoPage extends React.Component<VideoPageProps, VideoPageState> {
    private static readonly defaultFilename: string = 'my-ces-video.mp4';

    private static readonly platforms: Platforms = {
        facebook: 'facebook',
        twitter: 'twitter',
        linkedin: 'linkedin',
        email: 'email',
    };

    private static readonly shareIcos: ShareIcons = {
        facebook: <Facebook />,
        twitter: <Twitter />,
        email: <Email />,
        linkedin: <Linkedin />
    }

    private playerRef: HTMLVideoElement = null;
    private impsoiumPlayer: Player = null;

    private icoRef: HTMLDivElement = null;

    private jwPlayer : any;

    constructor(p) {
        super(p);

        this.state = {
            experienceData: null,
            title: '',
            subTitle: '',
            showTooltip: false
        };
    }

    componentWillMount() {
        const {settings: {isDeeplink}} = this.props;
        const {deeplinkName} = (window as any);

        let title: any = {__html: copy.header_default};
        let subTitle: any = {__html: copy.sub_header_default};

        if (isDeeplink) {
            title = {__html: this.replaceName(copy.header, `<br>${deeplinkName}`)};
            subTitle = {__html: this.replaceName(copy.sub_header, deeplinkName)};
            (window as any).ga('send', 'pageview', '/Commercial/copiedlink');
        } else {
            (window as any).ga('send', 'pageview', '/Commercial/viewvideo');
        }

        this.setState({title, subTitle});
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        const {params: {experience_id}} = this.props;

        //TODO: put analytics back in
        // this.impsoiumPlayer = new Player(this.playerRef, imposiumClient);
        // this.impsoiumPlayer.on(PlayerEvents.PLAY, () => this.onPlay(experience_id));

        imposiumClient.on(Events.GOT_EXPERIENCE, (d: any) => this.gotExperience(d));
        imposiumClient.getExperience(experience_id);

        this.jwPlayer = jwplayer('player')
    }

    componentWillUnmount() {
        this.impsoiumPlayer = null;
        imposiumClient.off(Events.GOT_EXPERIENCE);
    }

    getCategory = (): string => {
        const {settings: {isDeeplink}} = this.props;
        return (isDeeplink) ? 'UGC Commercial Deeplink' : 'UGC Commercial';
    }

    replaceName = (s: string, name: string): string => {
        return s.replace('[name]', name);
    }

    gotExperience = (experienceData: any): void => {
        this.setState({experienceData});

        this.jwPlayer.setup({
            width:'100%',
            file:experienceData.output.videos.mp4_720.url
        });
    }

    onPlay = (experienceId: string): void => {
        if (this.impsoiumPlayer.getPosition() == 0) {
            (window as any).ga('send', 'event', this.getCategory(), 'Play Final Video', experienceId);
        }
    }

    toggleTooltip = (showTooltip: boolean): void => {
        this.setState({showTooltip});
    }

    doDownload = (): void => {
        const {settings: {isChrome}} = this.props;
        const {experienceData} = this.state;

        if (experienceData) {
            const {output: {videos: {mp4_720: {url}}}} = experienceData;

            (window as any).ga('send', 'event', this.getCategory(), 'Download', '');

            if (isChrome) {
                Sharing.ajaxDownload(url, VideoPage.defaultFilename);
            } else {
                Sharing.hrefDownload(url, VideoPage.defaultFilename);
            }
        }
    }

    doCopy = (): void => {
        const {location: {href}} = window;
        const copied: boolean = copyToClipboard(href);
        (window as any).ga('send', 'event', this.getCategory(), 'Copy Link', '');
    }

    doShare = (platform: string): void => {
        const {twitter: twitterCopy, email_subject, email_body} = shareCopy;
        const {platforms: {facebook, twitter, email, linkedin}} = VideoPage;
        const {location: {href}} = window;

        switch (platform) {
            case facebook:
                Sharing.doFacebookShare(href);
                (window as any).ga('send', 'event', this.getCategory(), 'Share Start', 'FB');
                break;
            case twitter:
                Sharing.doTwitterShare(href, twitterCopy);
                (window as any).ga('send', 'event', this.getCategory(), 'Share Start', 'TW');
                break;
            case email:
                (window as any).ga('send', 'event', this.getCategory(), 'Share Start', 'email');
                Sharing.doEmailShare(email_subject, email_body, href);
                break;
            case linkedin:
                (window as any).ga('send', 'event', this.getCategory(), 'Share Start', 'LI');
                Sharing.doLinkedinShare(href);
                break;
            default:
                break;
        }
    }

    doMakeAnother = (): void => {
        const {resetInventory, resetValidation, settings: {isDeeplink}} = this.props;

        (window as any).ga('send', 'event', this.getCategory(), 'Make New Video', '');
        resetValidation();
        resetInventory();
        history.push('/');
    }

    render() {
        const {title, subTitle, showTooltip} = this.state;
        const {settings: {isDeeplink, mobileMode}} = this.props;
        const consumeRolloverClass: string = (!mobileMode) ? 'consume-rollover' : '';
        const shareRolloverClass: string = (!mobileMode) ? 'share-rollover' : '';

        let subTitleMarkup: JSX.Element = null;
        let shareWrapper: JSX.Element = null;
        let makeAnotherWrapper: JSX.Element = null;
        let tooltip: JSX.Element = null;

        if (showTooltip) {
            const {innerHeight: h, innerWidth: w, scrollY: sY, scrollX: sX} = window;
            const {top: t, right: r} = this.icoRef.getBoundingClientRect();
            const bottom: string = `${(h - sY) - t}px`;
            const right: string  = `${(w - sX) - r}px`;

            tooltip = (
                <div
                    className="tooltip-wrapper"
                    style={{bottom, right}}>

                    <div className="tooltip">
                        {copy.tooltip}
                    </div>
                </div>
            );
        }

        if (!isDeeplink) {
            makeAnotherWrapper = (
                <div
                    className = "make-another"
                    onClick = {() => this.doMakeAnother()}>

                    <div className="back-arrow" />
                    {copy.btn_make_another}
                </div>
            );
        }

        const shareButtons: JSX.Element[] = Object.keys(VideoPage.platforms).map((key: string) => {
            const platform: string = VideoPage.platforms[key];

            return (
                <div
                    key = {`share-btn-${platform}`}
                    className = {`btn btn-share btn-share-${platform} ${shareRolloverClass}`}
                    onClick = {() => this.doShare(platform)}>
                    {VideoPage.shareIcos[platform]}
                </div>
            );
        });

        return (
            <div className = "video-page">
                <div className="video-header">
                    <h1 dangerouslySetInnerHTML = {title} />
                    <p dangerouslySetInnerHTML = {subTitle} />
                    <div className = "rule" />
                </div>

                {tooltip}

                <div className = "flex-wrapper">

                    <div className = 'player-wrapper'>
                        <div id="player"></div>
                    </div>

                    <div className = "video-buttons">
                        <div className="social-buttons">
                            <div className = "consume-buttons">
                                <div
                                    className = {`btn btn-default btn-dl ${consumeRolloverClass}`}
                                    onClick={() => this.doDownload()}>

                                    {copy.btn_download}
                                </div>

                                <div className="copy-wrapper">
                                    <div
                                        className = {`btn btn-default btn-copy ${consumeRolloverClass}`}
                                        onClick={() => this.doCopy()}>

                                        {copy.btn_copy}
                                    </div>
                                </div>
                            </div>

                            <div className = "share-buttons">
                                <div className = "share-btn-wrapper">
                                    {shareButtons}
                                </div>
                            </div>
                        </div>

                        {makeAnotherWrapper}
                    </div>
                </div>
                <About color = "white"/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {settings: state.settings, inventory: state.inventory, prevExperience: state.prevExperience};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({resetInventory, resetValidation}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);