import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/root';

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunkMiddleware),
    // other store enhancers if any
));

export default store;