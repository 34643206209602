export const showValidation = (toggle: boolean) => {
    return (dispatch) => {
        dispatch({type: actions.TOGGLE_VISIBILITY, toggle});
    }
}

export const validateSection = (sectionName: string, status: boolean) => {
    return (dispatch) => {
        dispatch({type: actions.VALIDATE, sectionName, status});
    }
}

export const resetValidation = (): any => {
    return (dispatch) => {
        dispatch({type: actions.RESET});
    }
}

const actions: any = {
    TOGGLE_VISIBILITY: 'validation/TOGGLE_VISIBILITY',
    VALIDATE: 'validation/VALIDATE',
    RESET: 'validation/RESET'
};

export default actions;