
import actions from '../actions/validation';

const initialState: any = {
    show: false,
    sections: {
        contact: false,
        headshot: false,
        clips: false,
        music: false
    }
};

function validation(state = initialState, action) {
    switch (action.type) {
        case actions.TOGGLE_VISIBILITY:
            return {
                ...state,
                show: action.toggle
            };
        case actions.VALIDATE:
            return {
                ...state,
                sections: {
                    ...state.sections,
                    [action.sectionName]: action.status
                }
            };
        case actions.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default validation;