import actions from '../actions/prev-experience';

const initialState: any = {
    name: '',
    experienceId: ''
};

function prevExperience(state = initialState, action) {
    switch (action.type) {
        case actions.SET:
            return {...action.prevExperience};
        default:
            return state
    }
}

export default prevExperience;